<template>
  <div
    class="add-photo-view"
    :style="`width: ${width}`"
  >
    <div
      class="input-placeholder pointer"
      v-if="!hasPhoto"
      @click.prevent="selectFile"
      title="Cliquez pour ajouter une Photo ou un fichier"
    >
      <div
        class="spinner-border text-success"
        role="status"
        v-if="loading"
      >
        <span class="sr-only">Loading...</span>
      </div>
      <i
        class="las la-plus"
        v-else
      /> 
    </div>
    <div
      class="product-photo"
      v-else
    >
      <img
        class="product-image"
        :src="photoUrl"
      >
      <a
        href="javascript:void(0)"
        @click.prevent="deletingPhoto"
        class="delete-btn"
        title="Supprimer la photo ou le fichier"
      >
        <i class="las la-times" />
      </a>
    </div>
    <form
      action=""
      style="display: none;"
    >
      <input
        type="file"
        @change="addingProductPhoto"
        :id="`selectFile-${index}`"
        ref="file"
      >
    </form>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import axios from 'axios';
import { BASE } from '../../constants/app';
export default {
    props: {
        index: {type: Number, required: true},
        width: {type: String, default: '250px'}
    },
    watch:{
        photos:{
            handler(){
                if(this.photos[this.index] !== null && this.photos[this.index] !== undefined){
                    this.photo = this.photos[this.index]
                    this.photoUrl = this.photos[this.index].url
                    this.photoUid = this.photos[this.index].uid
                    this.photoName = this.photos[this.index].name
                    this.hasPhoto = true
                }else{
                    this.photo = null
                    this.photoUrl = null
                    this.photoUid = null
                    this.photoName = null
                    this.hasPhoto = false
                }
            },
            deep: true
        }
    },
    data(){
        return {
            hasPhoto: false,
            photo: null,
            photoUrl: null,
            photoUid: null,
            photoName: null,
            loading: false
        }
    },
    mounted(){
        this.hasPhoto =  false
        this.photo =  null
        this.photoUrl = null
        this.photoUid =  null
        this.photoName=  null
    },
    methods: {
        ...mapMutations({
            addPhoto: 'market/ADD_PRODUCT_PHOTO',
            deletePhoto: 'market/DELETE_PRODUCT_PHOTO'
        }),
        selectFile(){
            document.getElementById(`selectFile-${this.index}`).click()
        },
        addingProductPhoto(){
            this.photo = this.$refs.file.files[0];
            const formData = new FormData();
            formData.append('file', this.photo);
            this.loading = true
            // formData.append('oldfile', this.photoName);
            const headers = { 
                'Content-Type': 'multipart/form-data',
                'authorization': 'Bearer '+ this.token
            };
            let url =  (process.env.NODE_ENV === 'production')? `https://${BASE}/upload`: `http://${BASE}/upload`
            axios.post(url, formData, { headers }).then(({data}) => {
                this.hasPhoto = true
                console.log(data)
                this.addPhoto({uid: data.uid, url: data.url, name: data.name})
                this.photoUrl = data.url
                this.photoUid = data.uid
                this.photoName = data.name
                this.$emit('photo-add', data)
            }, error => {
                console.error(error)
            }).finally(() => {
                this.loading = false
            });
        },
        deletingPhoto(){
            this.loading = true
            console.log(`Deleting photo uid ${this.photoUid} on URL ${this.photoUrl}`)
            let url = (process.env.NODE_ENV === 'production')? `https://${BASE}/files/${this.photoName}/delete`: `http://${BASE}/files/${this.photoName}/delete`
            const headers = { 
                'Content-Type': 'multipart/form-data',
                'authorization': 'Bearer '+ this.token
            };
            axios.get(url, { headers }).then(({data}) => {
                this.hasPhoto = false
                console.log(data)
                this.deletePhoto({uid: this.photoUid, url: this.photoUrl, name: this.photoName})
                this.photoUrl = null
                this.photoUid = null
                this.photoName = null
            }, error => {
                console.error(error)
            }).finally(() => {
                this.loading = false
            });
        }
    },
    computed: {
        ...mapGetters({
            token: 'auth/token',
            photos: 'market/productPhotos'
        })
    }
}
</script>

<style lang="scss" scoped>
$third: #b50404;
.add-photo-view{
    // padding: 20px;
    // margin-bottom: 20px;
    display: inline-block;
    .input-placeholder{
        padding: 10px 0px; 
        text-align: center;
    }
    .product-photo{
        text-align: center;
        position: relative;
        .product-image{
            width: 100%;
            height: 100%;
            margin-bottom: 5px;
            display: block;
        }
        .delete-btn { 
          position: absolute;
          bottom: 0px;
          right: 0px;
          border-radius: 45%;
          border: $third solid 1px;
          color: $third;
          font-size: 10px !important;
        }
    }
    
}
</style>